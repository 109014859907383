<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Delete Mapping Group
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <form @submit.prevent="submit">
        <div class="">
          Delete mapping group "<span class="font-mono leading-tight">{{ group.name }}</span>"?
        </div>
        <div class="mt-6 btn-group flex items-center justify-end">
          <button
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="btn negative"
            type="button"
            @click.prevent="submit"
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {

  props: ['group', 'callback'],

  data () {
    return {
      form: new SparkForm({}),
      formFormHasErrors: false
    }
  },

  methods: {

    submit () {
      this.form.startProcessing()
      this.$emit('close')
      this.callback()

      // this.$store.dispatch('assets/newFolder', {
      //   project: this.project,
      //   parent: this.parent,
      //   name: this.form.name
      // })
      //   .then(result => {
      //     this.form.finishProcessing()
      //     this.$emit('close')
      //     alert.success('Folder created')
      //   })
      //   .catch(response => {
      //     alert.error('Failed to create folder')
      //     this.formFormHasErrors = true
      //     this.form.setErrors(response.data)
      //     this.form.finishProcessing()
      //   })
    }

  }
}
</script>
